import { ItemInfo, ItemRegistryBase } from "./item.interface";
import { ProcessingType } from "./processing.interface";
import { MissionDetails } from "./putaway-missions.interface";
import { Udm, UdmHost } from "./udm.interface";

export enum OrderProcessAction {
    NEW = 'NEW',
    EDIT = 'EDIT',
    VIEW = 'VIEW',
    PALLET_LIST = 'PALLET_LIST',
    PALLET_ADD = 'PALLET_ADD',
    VIEW_PHOTO = 'VIEW_PHOTO'
}

export enum OrderProcessStatus {
    ALL = -1,
    NEW = 0,
    ACTIVE = 5,
    READY = 10,
    IN_PROGRESS = 20,
    ON_PAUSE = 30,
    COMPLETED = 90,
    CONCLUDED = 95,
    CANCELLED = 99
}

export enum OrderMissionStatus {
    TODO = 20,
    IN_PROGRESS = 50,
    PICKING = 52,
    COMPLETED = 90,
    CANCELLED = 99
}

export enum OrderMissionType {
    PROCESSING_PICKING = "PPCK",
    PROCESSING_PICKING_MANUAL = "PPCKM"
}

export enum OrderProcessException {
    PICK_MOB_QTY_NA = 4, // Not enough
    PICK_MOB_BAD_PROD = 5, // Ruined goods
    PICK_UDM_NOT_FOUND = 6, // Pallet not found
    PICK_ITEM_NOT_FOUND = 7, // Item not found
    PROCESS_UDM_BARCODE_MISSING = 8 // Udm barcode missing
}

export interface OrderProcess {
    id?: number;
    priority?: number;
    processingDate?: number;
    processingOrderNumber?: string;
    state?: OrderProcessState;
    status?: string;
    note?: string;
    noteEndProcessing?: string;
    noteUnthrownWaste?: string;
    notePackaging?: string;
    withPhoto?: boolean;
    hasAnomaly?: boolean;
    details?: OrderProcessDetail[];
    dataChanged?: boolean;
    customerCode?: string;
    customerBusinessName?: string;
    parcelCalculationAnomaly?: boolean;
}

export interface OrderProcessHeader {
    orders?: OrderProcess[];
}

export interface OrderProcessDetail {
    id?: number;
    orderLineNo?: string;
    partnerCode?: string;
    partnerBusinessName?: string;
    productedGrossWeight?: number;
    productedNetWeight?: number;
    productedParcels?: number;
    productedPieces?: number;
    requiredGrossWeight?: number;
    requiredNetWeight?: number;
    requiredParcels?: number;
    requiredPieces?: number;
    itemGroupCode?: string;
    itemGroupDesc?: string;
    itemDescription?: string;
    order?: OrderProcess;
    product?: ItemInfo;
    productSpecies?: ItemRegistryBase;
    productOrigin?: ItemRegistryBase;
    productPackaging?: ItemRegistryBase;
    productPackagingLine?: ItemRegistryBase;
    productMaterial?: ItemRegistryBase;
    productPack?: ItemRegistryBase;
    productSize?: ItemRegistryBase;
    productCalibre?: ItemRegistryBase;
    productVariety?: ItemRegistryBase;
    entryDescription?: string;
    lot?: string;
    processedGrossWeight?: number;
    processedNetWeight?: number;
    processedParcels?: number;
    progress?: number;
    hasAnomaly?: boolean;
    missionDetails?: MissionDetails[];
    numOfActiveMissions?: number;
    udms?: Udm[];
    udmHosts?: UdmHost[];
    withPhoto?: boolean;
    status?: any;
    notes?: string;
    processingTypeFCC?: ProcessingType;
}

export interface OrderProcessState {
    value?: number;
	name?: string;
}