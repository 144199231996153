import { Component, EventEmitter, Output, Input } from '@angular/core';

export enum S1ButtonType {
  Normal = 'NORMAL',
  Back = 'BACK',
  New = 'NEW',
  Save = 'SAVE',
  Edit = 'EDIT',
  Delete = 'DELETE',
  Search = 'SEARCH',
  Link = 'LINK',
  Unlink = 'UNLINK',
  Add = 'ADD',
  Remove = 'REMOVE',
  Next = 'NEXT',
  Previous = 'PREVIOUS',
  Check = 'CHECK',
  Uncheck = 'UNCHECK',
  Cancel = 'CANCEL',
  Import = 'IMPORT',
  Send = 'SEND',
  Copy = 'COPY',
  View = 'VIEW',
  ResetFilter = 'RESET_FILTER',
  ApplyFilter = 'APPLY_FILTER',
  Reload = 'RELOAD',
  Sync = 'SYNC',
  Info = 'INFO',
  Close = 'CLOSE',
  Assign = 'ASSIGN',
  Free = 'FREE',
  Move = 'MOVE',
  ExpandMore = 'EXPAND_MORE',
  ExpandLess = 'EXPAND_LESS',
  Print = 'PRINT',
  Alert = 'ALERT',
  OpenDDT = 'OPEN_DDT',
  CloseDDT = 'CLOSE_DDT',
  Activate = 'ACTIVATE',
  Deactivate = 'DEACTIVATE',
  ViewSurvey = 'VIEW_SURVEY',
  DuplicateSurvey = 'DUPLICATE_SURVEY',
  AddSurvey = 'ADD_SURVEY',
  ViewPhotoGallery = 'VIEW_PHOTO_GALLERY',
  MoveGenerate = 'MOVE_GENERATE',
  Mail = 'MAIL',
  Generate = 'GENERATE',
  GeneratePDF = 'GENERATE_PDF',
  Download = 'DOWNLOAD',
  DownloadWarn = 'DOWNLOAD_WARN',
  EditUser = 'EDIT_USER',
  EditPriority = 'EDIT_PRIORITY',
  ViewExceptions = 'VIEW_EXCEPTIONS',
  DuplicateRule = 'DUPLICATE_RULE',
  ConcludeOrder = 'CONCLUDE_ORDER',
  ActivateOrder = 'ACTIVATE_ORDER',
  PrepareOrder = 'PREPARE_ORDER',
  ResetChecked = 'RESET_CHECKED',
  PrintChecked = 'PRINT_CHECKED',
  Resolve = 'RESOLVE',
  RetryPicking = 'RETRY_PICKING',
  CheckWeight = 'CHECK_WEIGHT',
  Verify = 'VERIFY',
  PalletList = 'PALLET_LIST',
  AssignAll = 'ASSIGN_ALL',
  CopyClipboard = 'COPY_CLIPBOARD',
  SearchItems = 'SEARCH_ITEMS'
}

interface IS1Button {
  class: string;
  classOutline: string;
  icon: string;
  label: string;
}

@Component({
  selector: 's1-button',
  templateUrl: './s1-button.component.html',
  styleUrls: ['./s1-button.component.scss']
})

export class S1Button {

  @Input() type: S1ButtonType = S1ButtonType.Normal;
  @Input() label: string;
  @Input() icon: string;
  @Input() isSubmit = false;
  @Input() disabled = false;
  @Input() hidden = false;
  @Input() outline = false;
  @Input() onlyIcon = false;
  @Input() onlyText = false;
  @Output() onClick = new EventEmitter<any>();

  s1ButtonType = S1ButtonType;

  styles: { [key in S1ButtonType]: IS1Button } = {
    NORMAL: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: '', label: '' },
    BACK: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-chevron-left', label: 's1.button.back' },
    NEW: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-plus', label: 's1.button.new' },
    SAVE: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-save', label: 's1.button.save' },
    EDIT: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-edit', label: 's1.button.edit' },
    DELETE: { class: 'btn-danger', classOutline: 'btn-outline-danger', icon: 'fa fa-trash-alt', label: 's1.button.delete' },
    SEARCH: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-search', label: 's1.button.search' },
    LINK: { class: 'btn-success', classOutline: 'btn-outline-success', icon: 'fa fa-link', label: 's1.button.link' },
    UNLINK: { class: 'btn-warning', classOutline: 'btn-outline-warning', icon: 'fa fa-unlink', label: 's1.button.unlink' },
    ADD: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-plus', label: 's1.button.add' },
    REMOVE: { class: 'btn-danger', classOutline: 'btn-outline-danger', icon: 'fa fa-trash-alt', label: 's1.button.remove' },
    NEXT: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-chevron-right', label: 's1.button.next' },
    PREVIOUS: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-chevron-left', label: 's1.button.previous' },
    CHECK: { class: 'btn-success', classOutline: 'btn-outline-success', icon: 'fa fa-check', label: 's1.button.check' },
    UNCHECK: { class: 'btn-danger', classOutline: 'btn-outline-danger', icon: 'fa fa-times', label: 's1.button.uncheck' },
    CANCEL: { class: 'btn-secondary', classOutline: 'btn-outline-secondary', icon: 'fa fa-undo-alt', label: 's1.button.cancel' },
    IMPORT: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-file-import', label: 's1.button.import' },
    SEND: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-paper-plane', label: 's1.button.send' },
    COPY: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-copy', label: 's1.button.copy' },
    VIEW: { class: 'btn-secondary', classOutline: 'btn-outline-secondary', icon: 'fa fa-eye', label: 's1.button.view' },
    RESET_FILTER: { class: 'btn-danger', classOutline: 'btn-outline-danger', icon: 'fa fa-trash-alt', label: 's1.button.resetFilter' },
    APPLY_FILTER: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-filter', label: 's1.button.applyFilter' },
    RELOAD: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fas fa-sync', label: 's1.button.reload' },
    SYNC: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fas fa-sync', label: 's1.button.sync' },
    INFO: { class: 'btn-light', classOutline: 'btn-outline-light', icon: 'fas fa-info', label: 's1.button.info' },
    CLOSE: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fas fa-times', label: 's1.button.close' },
    ASSIGN: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'far fa-hand-point-right', label: 's1.button.assign' },
    FREE: { class: 'btn-danger', classOutline: 'btn-outline-danger', icon: 'far fa-hand-paper', label: 's1.button.free' },
    MOVE: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fas fa-arrows-alt', label: 's1.button.move' },
    EXPAND_MORE: { class: 'btn-light', classOutline: 'btn-outline-light', icon: 'fas fa-angle-down', label: 's1.button.expand_more' },
    EXPAND_LESS: { class: 'btn-light', classOutline: 'btn-outline-light', icon: 'fas fa-angle-up', label: 's1.button.expand_less' },
    PRINT: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fas fa-print', label: 's1.button.print' },
    ALERT: { class: 'btn-warning', classOutline: 'btn-outline-warning', icon: 'fas fa-exclamation-triangle', label: 's1.button.alert' },
    OPEN_DDT: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fas fa-lock-open', label: 's1.button.open_ddt' },
    CLOSE_DDT: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fas fa-lock', label: 's1.button.close_ddt' },
    ACTIVATE: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fas fa-toggle-off fa-1-25x', label: 's1.button.activate' },
    DEACTIVATE: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fas fa-toggle-on fa-1-25x', label: 's1.button.deactivate' },
    VIEW_SURVEY: { class: 'btn-secondary', classOutline: 'btn-outline-secondary', icon: 'fas fa-clipboard-check', label: 's1.button.view_survey' },
    DUPLICATE_SURVEY: { class: 'btn-info', classOutline: 'btn-outline-primary', icon: 'fa fa-clone', label: 's1.button.duplicate_survey' },
    ADD_SURVEY: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-plus', label: 's1.button.add_survey' },
    VIEW_PHOTO_GALLERY: { class: 'btn-secondary', classOutline: 'btn-outline-secondary', icon: 'fas fa-camera', label: 's1.button.view_photo_gallery' },
    MOVE_GENERATE: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fas fa-arrow-right', label: 's1.button.move_generate' },
    MAIL: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-envelope', label: 's1.button.mail' },
    GENERATE: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-table', label: 's1.button.generate' },
    GENERATE_PDF: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-save', label: 's1.button.generate_pdf' },
    DOWNLOAD: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-download', label: 's1.button.download' },
    DOWNLOAD_WARN: { class: 'btn-warning', classOutline: 'btn-outline-primary', icon: 'fa fa-download', label: 's1.button.download' },
    EDIT_USER: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-user-edit', label: 's1.button.edit_user' },
    EDIT_PRIORITY: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-sort-amount-up', label: 's1.button.edit_priority' },
    VIEW_EXCEPTIONS: { class: 'btn-secondary', classOutline: 'btn-outline-secondary', icon: 'fas fa-file-medical-alt', label: 's1.button.view_exceptions' },
    DUPLICATE_RULE: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-clone', label: 's1.button.duplicate_rule' },
    CONCLUDE_ORDER: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-check', label: 's1.button.conclude_order' },
    ACTIVATE_ORDER: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-play', label: 's1.button.activate_order' },
    PREPARE_ORDER: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fas fa-arrow-right', label: 's1.button.prepare_order' },
    RESET_CHECKED: { class: 'btn-danger', classOutline: 'btn-outline-danger', icon: 'fa fa-trash-alt', label: 's1.button.reset_checked' },
    PRINT_CHECKED: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-filter', label: 's1.button.applyFilter' },
    RESOLVE: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-check', label: 's1.button.resolve' },
    RETRY_PICKING: { class: 'btn-info', classOutline: 'btn-outline-info', icon: 'fas fa-sync', label: 's1.button.retry_picking' },
    CHECK_WEIGHT: { class: 'btn-primary', classOutline: 'btn-outline-info', icon: 'fas fa-weight-hanging', label: 's1.button.check_weight' },
    VERIFY: { class: 'btn-success', classOutline: 'btn-outline-success', icon: 'fa fa-check', label: 's1.button.verify' },
    PALLET_LIST: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-pallet', label: 's1.button.pallet_list' },
    ASSIGN_ALL: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-save', label: 's1.button.assign_all' },
    COPY_CLIPBOARD: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-clipboard', label: 's1.button.copy_clipboard' },
    SEARCH_ITEMS: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon: 'fa fa-search', label: 's1.button.search_items' },
  };

  constructor() { }

  click() {
    this.onClick.emit();
  }

}
