export const CORE_LOAD_PRIVILEGES                                       = "/core/configuration/load_file"                                                    ;
export const CORE_USER_LIST                                             = "/core/user/all"                                                                   ;
export const CORE_USER_CREATE                                           = "/core/user/create"                                                                ;
export const CORE_USER_UPDATE                                           = "/core/user/update"                                                                ;
export const CORE_USER_GROUPS                                           = '/core/user/groups'                                                                ;
export const CORE_USER_GROUPS_OF_USER                                   = '/core/user/groups/:idUser'                                                        ;
export const CORE_USER_PERMISSIONS                                      = '/core/user/permissions'                                                           ;
export const CORE_USER_PERMISSIONS_OF_USER                              = '/core/user/permissions/:idUser'                                                   ;
export const CORE_USER_LIST_TO_COPY                                     = '/core/user/list_to_copy'                                                          ;
export const CORE_USER                                                  = '/core/user/:idUser'                                                               ;
export const CORE_SESSION_METADATA                                      = '/core/user/user-session/metadata'                                                 ;
export const CORE_COOPERATIVE_LIST                                      = '/core/cooperative/list'                                                           ;
export const CORE_LOCATION_LIST                                         = '/core/location/all'                                                               ;
export const CORE_LOCATION_GENERATE_CHECK_DIGIT                         = '/core/location/generate_check_digit'                                              ;
export const CORE_LOCATION_PUTAWAY_BLOCKS_LIST                          = '/core/location/putaway_blocks/list'                                               ;
export const CORE_LOCATION_PICKING_BLOCKS_LIST                          = '/core/location/picking_blocks/list'                                               ;
export const CORE_LOCATION_CREATE                                       = "/core/location/create"                                                            ;
export const CORE_LOCATION_UPDATE                                       = "/core/location/update"                                                            ;
export const CORE_LOCATION_DELETE                                       = "/core/location/delete/:idLocation"                                                ;
export const CORE_LOCATION_DATA                                         = "/core/location/data/:idLocation"                                                  ;
export const CORE_LOCATION_CLASS_TYPE                                   = "/core/location/location_class_type/list"                                          ;
export const CORE_LOCATION_BLOCK_PICKING                                = "/core/location/block/picking/:idLocation"                                         ;
export const CORE_LOCATION_BLOCK_PUTAWAY                                = "/core/location/block/putaway/:idLocation"                                         ;
export const CORE_LOCATION_ZONE_PICKING                                 = "/core/location/zone/picking/:idLocation"                                          ;
export const CORE_LOCATION_ZONE_PUTAWAY                                 = "/core/location/zone/putaway/:idLocation"                                          ;
export const CORE_ZONE_ALL                                              = "/core/zone/all"                                                                   ;
export const CORE_ZONE_DATA                                             = "/core/zone/data/:idZone"                                                          ;
export const CORE_ZONE_SEARCH_LOCATION                                  = "/core/zone/location/search"                                                       ;
export const CORE_ZONE_CREATE                                           = "/core/zone/create"                                                                ;
export const CORE_ZONE_UPDATE                                           = "/core/zone/update"                                                                ;
export const CORE_ZONE_DELETE                                           = "/core/zone/delete/:idZone"                                                        ;
export const CORE_VENDOR_ALL                                            = "/core/vendor/all"                                                                 ;
export const CORE_LAST_JOB_STATUS                                       = "/core/job/last/:service"                                                          ;
export const CORE_ITEM_ALL                                              = "/core/product/all"                                                                ;
export const CORE_ITEM_CALIBRE_ALL                                      = "/core/product/product_calibre/all"                                                ;
export const CORE_ITEM_ORIGIN_ALL                                       = "/core/product/product_origin/all"                                                 ;
export const CORE_ITEM_PROCESSING_ALL                                   = "/core/product/product_processing/all"                                             ;
export const CORE_ITEM_QUALITY_ALL                                      = "/core/product/product_quality/all"                                                ;
export const CORE_ITEM_SIZE_ALL                                         = "/core/product/product_size/all"                                                   ;
export const CORE_ITEM_SPECIE_ALL                                       = "/core/product/product_species/all"                                                ;
export const CORE_ITEM_TYPOLOGY_ALL                                     = "/core/product/product_typology/all"                                               ;
export const CORE_ITEM_VARIETY_ALL                                      = "/core/product/product_variety/all"                                                ;
export const CORE_ITEM_PLATFORM_ALL                                     = "/core/product/product_platform/all"                                               ;
export const CORE_ITEM_PLATFORM_CREATE                                  = "/core/product/product_platform/create"                                            ;
export const CORE_ITEM_PLATFORM_UPDATE                                  = "/core/product/product_platform/update"                                            ;
export const CORE_JOB_ALL                                               = "/core/job/all"                                                                    ;
export const CORE_PRINTER_ALL                                           = "/core/printer/all"                                                                ;
export const CORE_PRINTER_CREATE                                        = "/core/printer/create"                                                             ;
export const CORE_PRINTER_UPDATE                                        = "/core/printer/update"                                                             ;
export const CORE_PRINTER_TOGGLE_STATE                                  = "/core/printer/toggle/state/:idPrinter"                                            ;
export const CORE_PRINTER_DOWNLOAD_FLAP                                 = "/core/printer/download_flap"                                                      ;
export const CORE_PRINTER_PRINT_FLAP                                    = "/core/printer/print_flap"                                                         ;
export const CORE_PRINTER_DOWNLOAD_LOCATION_FLAP                        = "/core/location/download-location-check-digit"                                     ;
export const CORE_PRINTER_PRINT_LOCATION_FLAP                           = "/core/location/print-location-check-digit"                                        ;
export const CORE_CUSTOMER_ALL                                          = "/core/customer/all"                                                               ;
export const CORE_CUSTOMER_TOGGLE_FAVOURITE                             = "/core/customer/toggle/favourite/:idCustomer"                                      ;
export const CORE_PRODUCT_THRESHOLD_ALL                                 = "/core/product/product_threshold/all"                                              ;
export const CORE_PRODUCT_THRESHOLD_CREATE                              = "/core/product/product_threshold/create"                                           ;
export const CORE_PRODUCT_THRESHOLD_UPDATE                              = "/core/product/product_threshold/update"                                           ;
export const CORE_PRODUCT_THRESHOLD_DELETE                              = "/core/product/product_threshold/:idProductThreshold"                              ;
export const CORE_PRODUCT_THRESHOLD_GENERAL                             = "/core/product/product_threshold/general"                                          ;
export const CORE_PRODUCT_THRESHOLD_GENERAL_UPDATE                      = "/core/product/product_threshold/general/update"                                   ;
export const CORE_PRODUCT_TYPOLOGY_CUSTOMER_ALL_TIPOLOGIES_CUSTOMERS    = "/core/product/product-typology-customer/all-tipologies-customers"                 ;
export const CORE_PRODUCT_TYPOLOGY_CUSTOMER_GENERAL_TIPOLOGY_CUSTOMERS  = "/core/product/product-typology-customer/general-typology-customers"               ;
export const CORE_PRODUCT_TYPOLOGY_CUSTOMER_ALL_CUSTOMERS_TYPOLOGY      = "/core/product/product-typology-customer/all-customers-typology"                   ;
export const CORE_PRODUCT_TYPOLOGY_CUSTOMER_CREATE                      = "/core/product/product-typology-customer/create"                                   ;
export const CORE_PRODUCT_TYPOLOGY_CUSTOMER_DELETE_ALL_CUSTOMERS        = "/core/product/product-typology-customer/delete-all-customers/:idProductTypology"  ;
export const CORE_CAUSAL_ALL                                            = "/core/causal/all"                                                                 ;
export const CORE_CAUSAL_CREATE                                         = "/core/causal/create"                                                              ;
export const CORE_CAUSAL_UPDATE                                         = "/core/causal/update"                                                              ;
export const CORE_PURPOSE_ALL                                           = "/core/purpose/all"                                                                ;
export const CORE_PURPOSE_CREATE                                        = "/core/purpose/create"                                                             ;
export const CORE_PURPOSE_UPDATE                                        = "/core/purpose/update"                                                             ;
export const CORE_MAILING_LIST_ALL                                      = "/core/mailing-list/all"                                                           ;
export const CORE_MAILING_LIST_CREATE                                   = "/core/mailing-list/create"                                                        ;
export const CORE_REPORT                                                = "/core/report/:idReport"                                                           ;
export const CORE_REPORT_GENERATE                                       = "/core/report/generate"                                                            ;
export const CORE_REPORT_EXPORT_CSV                                     = "/core/report/:idReport/export_csv"                                                ;
export const CORE_MISSION_ALL                                           = '/core/mission/all'                                                                ;
export const CORE_MISSION_ALL_EXPORT_CSV                                = '/core/mission/all/export-csv'                                                     ;
export const CORE_MISSION_DETAIL_ALL                                    = '/core/mission/detail/all'                                                         ;
export const CORE_MISSION_CHANGE_USER                                   = '/core/mission/change-user'                                                        ;
export const CORE_MISSION_CHANGE_PRIORITY                               = '/core/mission/change-priority'                                                    ;
export const CORE_MISSION_DELETE                                        = '/core/mission/cancel/:missionId'                                                  ;
export const CORE_MISSION_DETAIL_DELETE                                 = '/core/mission/cancel-detail/:missionDetailsId'                                    ;
export const CORE_MISSION_EXCEPTION_ALL                                 = '/core/mission-exception/all'                                                      ;
export const CORE_STOCK_PRODUCT_ALL                                     = '/core/stock/product/all'                                                          ;
export const CORE_STOCK_PRODUCT_EXPORT_CSV                              = '/core/stock/product/all/export-csv'                                               ;
export const CORE_STOCK_LOCATION_ALL                                    = '/core/stock/location/all'                                                         ;
export const CORE_STOCK_LOCATION_EXPORT_CSV                             = '/core/stock/location/all/export-csv'                                              ;
export const CORE_STOCK_UDM_ALL                                         = '/core/stock/udm/all'                                                              ;
export const CORE_STOCK_UDM_EXPORT_CSV                                  = '/core/stock/udm/all/export-csv'                                                   ;
export const CORE_API_CALL_LOG_ALL                                      = '/core/api-call-log/all'                                                           ;
export const CORE_API_CALL_LOG_LOG_EXTRACTION                           = '/core/api-call-log/log-extraction/:idApiCallLog'                                  ;
export const RECEIVMENT_ORDER_ALL                                       = "/receivment/order/all"                                                            ;
export const RECEIVMENT_ORDER_EXPORT                                    = "/receivment/order/export_csv"                                                     ;
export const RECEIVMENT_ORDER_ANOMALIES                                 = "/receivment/webapp/order/order_anomaly/get_anomalies/:idOrder"                    ;
export const RECEIVMENT_ORDER_ANOMALIES_UPDATE                          = "/receivment/webapp/order/order_anomaly/update"                                    ;
export const RECEIVMENT_ORDER_DETAIL                                    = "/receivment/order/order_detail/:idOrder"                                          ;
export const RECEIVMENT_ORDER_DETAIL_ALL                                = "/receivment/order/order_detail/all"                                               ;
export const RECEIVMENT_ORDER_DETAIL_EXPORT_CSV                         = "/receivment/order/order_detail/export_csv"                                        ;
export const RECEIVMENT_ORDER_DETAIL_RECEIVMENT                         = "/receivment/order/product/receivment"                                             ;
export const RECEIVMENT_ORDER_DETAIL_RECEIPT                            = "/receivment/webapp/receipt/get_receipt/:idOrderDetail"                            ;
export const RECEIVMENT_ORDER_ORDER_ANOMALY_SAVE                        = "/receivment/webapp/order/order_anomaly/export_csv"                                ;
export const RECEIVMENT_DOCK_ALL                                        = "/receivment/webapp/loading_dock/all"                                              ;
export const RECEIVMENT_DOCK_FREE                                       = "/receivment/webapp/loading_dock/free/:idDock"                                     ;
export const RECEIVMENT_DOCK_MOVE                                       = "/receivment/webapp/loading_dock/move/:idOldDock/:idNewDock"                       ;
export const RECEIVMENT_DOCK_DDT_LIST                                   = "/receivment/webapp/loading_dock/ddt_list/:idDock"                                 ;
export const RECEIVMENT_DOCK_ORDER_SEARCH                               = "/receivment/webapp/loading_dock/order/search"                                     ;
export const RECEIVMENT_DDT_LIST                                        = "/receivment/webapp/order_ddt/all"                                                 ;
export const RECEIVMENT_DDT_EXPORT_CSV                                  = "/receivment/webapp/order_ddt/export_csv"                                          ;
export const RECEIVMENT_DDT_TOGGLE_STATE                                = "/receivment/webapp/order_ddt/toggle/state/:idDdt"                                 ;
export const RECEIVMENT_ORDER_DDT_CREATE                                = "/receivment/webapp/order_ddt/create"                                              ;
export const RECEIVMENT_ORDER_DDT_UPDATE                                = "/receivment/webapp/order_ddt/update"                                              ;
export const RECEIVMENT_ORDER_DDT_UPDATES                               = "/receivment/webapp/order_ddt/updates"                                             ;
export const RECEIVMENT_ORDER_DDT_DELETES                               = "/receivment/webapp/order_ddt/deletes"                                             ;
export const RECEIVMENT_DDT_DELETE                                      = "/receivment/webapp/order_ddt/delete/:idDdt"                                       ;
export const RECEIVMENT_ORDER_DDT_DETAIL                                = "/receivment/webapp/order_ddt/:idOrderDdt"                                         ;
export const RECEIVMENT_RECEIPT_ALL                                     = "/receivment/webapp/receipt/all"                                                   ;
export const RECEIVMENT_SYNC_RECEIPT                                    = "/receivment/webapp/receipt/navgreen_sync/:idReceiving"                            ;
export const RECEIVMENT_RECEIPT_ATTEMPTS                                = "/receivment/job/last_ten_syncro/:idReceiving"                                     ;
export const RECEIVMENT_SCAN_BARCODE                                    = "/receivment/webapp/receipt/scan_barcode/with_ddts"                                ;
export const RECEIVMENT_SAVE_RECEIVING                                  = "/receivment/webapp/receipt/save_receipt"                                          ;
export const RECEIVMENT_ALL_SYNC                                        = "/receivment/webapp/receipt/all_sync/:receiptDate"                                 ;
export const QUALITY_CONTROL_SURVEY_ALL                                 = "/quality-control/webapp/survey/all"                                               ;
export const QUALITY_CONTROL_SURVEY                                     = "/quality-control/webapp/survey/:idSurvey"                                         ;
export const QUALITY_CONTROL_SURVEY_CREATE                              = "/quality-control/webapp/survey"                                                   ;
export const QUALITY_CONTROL_SURVEY_UPDATE                              = "/quality-control/webapp/survey/:idSurvey"                                         ;
export const QUALITY_CONTROL_SURVEY_TOGGLE_STATE                        = "/quality-control/webapp/survey/toggle/state/:idSurvey"                            ;
export const QUALITY_CONTROL_SURVEY_DELETE                              = "/quality-control/webapp/survey/:idSurvey"                                         ;
export const QUALITY_CONTROL_SURVEY_REPORT_PDF                          = "/quality-control/webapp/survey/report"                                            ;
export const QUALITY_CONTROL_SURVEY_EXECUTION                           = "/quality-control/webapp/survey/execution/:idSurveyExecution"                      ;
export const QUALITY_CONTROL_SURVEY_EXECUTION_ALL                       = "/quality-control/webapp/survey/execution/all"                                     ;
export const QUALITY_CONTROL_SURVEY_EXECUTION_DELETE                    = "/quality-control/webapp/survey/execution/:idSurveyExecution/udm/:idUdm"           ;
export const QUALITY_CONTROL_SURVEY_EXECUTION_SINGLE_DATA               = "/quality-control/webapp/survey/execution/data"                                    ;
export const QUALITY_CONTROL_SURVEY_EXECUTION_SINGLE                    = "/quality-control/webapp/survey/execution"                                         ;
export const QUALITY_CONTROL_SURVEY_EXECUTION_DUPLICATE                 = "/quality-control/webapp/survey/execution/udm/:idUdm/duplicate"                    ;
export const QUALITY_CONTROL_UDM_ALL                                    = "/quality-control/webapp/udm/all"                                                  ;
export const QUALITY_CONTROL_UDM                                        = "/quality-control/webapp/udm/:idUdm"                                               ;
export const QUALITY_CONTROL_UDM_EXPORT_CSV                             = "/quality-control/webapp/udm/export_csv"                                           ;
export const QUALITY_CONTROL_UDM_CANCEL                                 = "/quality-control/webapp/udm/cancel/:idUdm"                                        ;
export const QUALITY_CONTROL_UDM_CHECK_ALL                              = "/quality-control/webapp/udm-check/all"                                            ;
export const QUALITY_CONTROL_UDM_CHECK                                  = "/quality-control/webapp/udm-check/:idUdmCheck"                                    ;
export const QUALITY_CONTROL_UDM_CHECK_CREATE                           = "/quality-control/webapp/udm-check/create"                                         ;
export const QUALITY_CONTROL_UDM_CUSTOMERS                              = "/quality-control/webapp/udm/:idUdm/customers"                                     ;
export const QUALITY_CONTROL_UDM_BLOCK_LIST                             = "/quality-control/webapp/udm/block/list"                                           ;
export const QUALITY_CONTROL_UDM_BLOCK_LIST_GROUPED                     = "/quality-control/webapp/udm/block/list-grouped"                                   ;
export const QUALITY_CONTROL_UDM_BLOCK                                  = "/quality-control/webapp/udm/block/:idUdm"                                         ;
export const QUALITY_CONTROL_UDM_BLOCK_GROUPED                          = "/quality-control/webapp/udm/block/:idUdm/grouped"                                 ;
export const QUALITY_CONTROL_UDM_BLOCK_ASSIGN                           = "/quality-control/webapp/udm/block/assign"                                         ;
export const QUALITY_CONTROL_UDM_FILE_ALL                               = "/quality-control/webapp/udm/storage/all/:idUdm"                                   ;
export const QUALITY_CONTROL_UDM_FILE                                   = "/quality-control/webapp/udm/storage/get/:idFile"                                  ;
export const QUALITY_CONTROL_UDM_FILE_DELETE                            = "/quality-control/webapp/udm/storage/delete/:idUdm/:idFile"                        ;
export const QUALITY_CONTROL_UDM_THRESHOLD_VERIFY_ALL                   = "/quality-control/webapp/udm/threshold-verify/all"                                 ;
export const QUALITY_CONTROL_UDM_THRESHOLD_VERIFY_EXPORT_CSV            = "/quality-control/webapp/udm/threshold-verify/export_csv"                          ;
export const QUALITY_CONTROL_DIFFERENTIAL_ALL                           = "/quality-control/webapp/differential/all"                                         ;
export const QUALITY_CONTROL_UDM_SYNC_DIFFERENTIAL                      = "/quality-control/webapp/udm/navgreen-sync-differential/:idUdm"                    ;
export const QUALITY_CONTROL_UDM_ALL_SYNC                               = "/quality-control/webapp/udm/all_sync/:referenceDate"                              ;
export const QUALITY_CONTROL_UDM_STOCK_UPDATE                           = "/quality-control/webapp/udm/stock/update"                                         ;
export const QUALITY_CONTROL_UDM_STOCK_CORRECTION                       = "/quality-control/webapp/udm/stock/correction"                                     ;
export const QUALITY_CONTROL_UDM_PRODUCT_PLATFORM_UPDATE                = "/quality-control/webapp/udm/product-platform/:idUdm"                              ;
export const QUALITY_CONTROL_UDM_RETURNED                               = "/quality-control/webapp/udm/return-udm"                                           ;
export const PUTAWAY_RULE_ALL                                           = '/putaway/webapp/rule/all'                                                         ;
export const PUTAWAY_RULE_CREATE                                        = '/putaway/webapp/rule/create'                                                      ;
export const PUTAWAY_RULE_DETAIL                                        = '/putaway/webapp/rule/:ruleId'                                                     ;
export const PUTAWAY_RULE_UPDATE                                        = '/putaway/webapp/rule/update'                                                      ;
export const PUTAWAY_RULE_DELETE                                        = '/putaway/webapp/rule/:ruleId'                                                     ;
export const PUTAWAY_UDM_MOVE                                           = '/putaway/webapp/move-udm'                                                         ;
export const PUTAWAY_UDM_GENERATE                                       = '/putaway/webapp/generate'                                                         ;
export const CORE_MULTISELECT_BUILDING                                  = '/core/multiselect/building'                                                       ;
export const CORE_MULTISELECT_LOCATION_ASSOCIATED_PRODUCT               = '/core/multiselect/location_associated_product'                                    ;
export const CORE_MULTISELECT_LOCATION_CLASS                            = '/core/multiselect/location_class'                                                 ;
export const CORE_MULTISELECT_LOCATION_TYPE                             = '/core/multiselect/location_type'                                                  ;
export const CORE_MULTISELECT_LOCATIONS_SIDE                            = '/core/multiselect/sides'                                                          ;
export const CORE_MULTISELECT_PRODUCTS                                  = '/core/multiselect/products'                                                       ;
export const CORE_MULTISELECT_PRODUCT_CALIBRE                           = '/core/multiselect/products/product_calibre'                                       ;
export const CORE_MULTISELECT_PRODUCT_ORIGIN                            = '/core/multiselect/products/product_origin'                                        ;
export const CORE_MULTISELECT_PRODUCT_PROCESSING                        = '/core/multiselect/products/product_processing'                                    ;
export const CORE_MULTISELECT_PRODUCT_QUALITY                           = '/core/multiselect/products/product_quality'                                       ;
export const CORE_MULTISELECT_PRODUCT_SIZE                              = '/core/multiselect/products/product_size'                                          ;
export const CORE_MULTISELECT_PRODUCT_SPECIE                            = '/core/multiselect/products/product_species'                                       ;
export const CORE_MULTISELECT_PRODUCT_TYPOLOGY                          = '/core/multiselect/products/product_typology'                                      ;
export const CORE_MULTISELECT_PRODUCT_VARIETY                           = '/core/multiselect/products/product_variety'                                       ;
export const CORE_MULTISELECT_PRODUCT_PACKAGING                         = '/core/multiselect/products/product_packaging'                                     ;
export const CORE_MULTISELECT_PRODUCT_PACKAGING_LINE                    = '/core/multiselect/products/product_packaging_line'                                ;
export const CORE_MULTISELECT_PRODUCT_MATERIAL                          = '/core/multiselect/products/product_material'                                      ;
export const CORE_MULTISELECT_PRODUCT_PACK                              = '/core/multiselect/products/product_pack'                                          ;
export const CORE_MULTISELECT_SITE                                      = '/core/multiselect/site'                                                           ;
export const CORE_MULTISELECT_ZONE_TYPE                                 = '/core/multiselect/zone_type'                                                      ;
export const CORE_MULTISELECT_PRODUCT_TYPOLOGY_WITHOUT_CUSTOMERS        = '/core/multiselect/products/product_typology_without_customers'                    ;
export const CORE_MULTISELECT_CUSTOMER_FAVOURITE                        = '/core/multiselect/customer-favourite'                                             ;
export const CORE_MULTISELECT_CAUSAL                                    = '/core/multiselect/causal'                                                         ;
export const CORE_MULTISELECT_CAUSAL_FOR_SITE                           = '/core/multiselect/causal/:idSite'                                                 ;
export const CORE_MULTISELECT_PURPOSE                                   = '/core/multiselect/purpose'                                                        ;
export const CORE_MULTISELECT_PURPOSE_FOR_SITE                          = '/core/multiselect/purpose/:idSite'                                                ;
export const CORE_MULTISELECT_COMPANY                                   = '/core/multiselect/company'                                                        ;
export const CORE_MULTISELECT_REPORT_TYPE                               = '/core/multiselect/report-type'                                                    ;
export const CORE_MULTISELECT_PRODUCT_PLATFORM                          = '/core/multiselect/products/product_platform'                                      ;
export const CORE_MULTISELECT_MISSION_TYPE                              = '/core/multiselect/mission-type'                                                   ;
export const CORE_MULTISELECT_MISSION_SCOPE                             = '/core/multiselect/mission-scope'                                                  ;
export const CORE_MULTISELECT_MISSION_STATE                             = '/core/multiselect/mission-state'                                                  ;
export const CORE_MULTISELECT_STOCK_MOVEMENT_TYPE                       = '/core/multiselect/stock-movement-type'                                            ;
export const CORE_MULTISELECT_LOCATION                                  = '/core/multiselect/location'                                                       ;
export const CORE_MULTISELECT_ORDER_SALE_STATE                          = '/core/multiselect/order-sale-state'                                               ;
export const CORE_MULTISELECT_ORDER_PROCESS_STATE                       = '/core/multiselect/order-process-state'                                            ;
export const RECEIVMENT_MULTISELECT_VENDOR                              = '/receivment/multiselect/order/search/vendors/:searchString'                       ;
export const RECEIVMENT_MULTISELECT_ENTRY                               = '/receivment/multiselect/order/entry_description/:referenceTs'                     ;
export const RECEIVMENT_MULTISELECT_LOT                                 = '/receivment/multiselect/order/lot/:referenceTs'                                   ;
export const RECEIVMENT_MULTISELECT_AVAILABLE_DOCK                      = '/receivment/multiselect/loading_dock/available'                                   ;
export const RECEIVMENT_MULTISELECT_PRINTER                             = '/receivment/multiselect/printer'                                                  ;
export const RECEIVMENT_MULTISELECT_DDT                                 = '/receivment/multiselect/printer/:barcode'                                         ;
export const QUALITY_CONTROL_MULTISELECT_SURVEY_INPUT_TYPE              = '/quality-control/multiselect/survey-input-type'                                   ;
export const QUALITY_CONTROL_MULTISELECT_SURVEY                         = '/quality-control/multiselect/survey'                                              ;
export const QUALITY_CONTROL_MULTISELECT_DIFFERENTIAL                   = '/quality-control/multiselect/differential'                                        ;
export const QUALITY_CONTROL_MULTISELECT_UDM_STATE_ACTIVE               = '/quality-control/multiselect/udm-state-active'                                    ;
export const PUTAWAY_MULTISELECT_UDM_STATE                              = '/putaway/multiselect/udm-state'                                                   ;
export const PICKING_ORDER_SALE_ALL                                     = '/picking/order-sale/all'                                                          ;
export const PICKING_ORDER_SALE_EXPORT_CSV                              = '/picking/order-sale/all/export-csv'                                               ;
export const PICKING_ORDER_SALE_DETAIL                                  = '/picking/order-sale/detail/:orderId'                                              ;
export const PICKING_ORDER_SALE_START_ORDER                             = '/picking/order-sale/start-order'                                                  ;
export const PICKING_ORDER_SALE_UPDATE_STATE                            = '/picking/order-sale/update-state'                                                 ;
export const PICKING_ORDER_SALE_UPDATE_NOTE                             = '/picking/order-sale/update-note'                                                  ;
export const PICKING_ORDER_SALE_CHANGE_PRIORITY                         = '/picking/order-sale/change-priority'                                              ;
export const PICKING_ORDER_SALE_SYNC_VARIATION                          = '/picking/order-sale/sync-variation'                                               ;
export const PICKING_ORDER_SALE_EXPORT_ANOMALIES                        = '/picking/order-sale/anomaly/all/export-csv'                                       ;
export const PICKING_ORDER_SALE_ALL_SYNC_SHIPPED                        = '/picking/order-sale/all-sync-shipped/:orderId'                                    ;
export const PICKING_ORDER_SALE_SYNC_SHIPPED                            = '/picking/order-sale/sync-shipped'                                                 ;
export const PICKING_ORDER_PROCESS_ALL                                  = '/picking/order-process/all'                                                       ;
export const PICKING_ORDER_PROCESS_DETAIL                               = '/picking/order-process/detail/:processId'                                         ;
export const PICKING_ORDER_PROCESS_DETAIL_FULL                          = '/picking/order-process/detailFull/:processId'                                     ;
export const PICKING_ORDER_PROCESS_UPDATE_STATE                         = '/picking/order-process/update-state'                                              ;
export const PICKING_ORDER_PROCESS_CHANGE_PRIORITY                      = '/picking/order-process/change-priority'                                           ;
export const PICKING_ORDER_PROCESS_SYNC_VARIATION                       = '/picking/order-process/sync-variation'                                            ;
export const PICKING_ORDER_PROCESS_EXPORT_CSV                           = '/picking/order-process/all/export-csv'                                            ;
export const PICKING_ORDER_PROCESS_EXPORT_ANOMALIES                     = '/picking/order-process/anomaly/all/export-csv'                                    ;
export const PICKING_ORDER_PROCESS_ALL_SYNC_BARCODE                     = '/picking/order-process/all-sync-barcode/:orderId'                                 ;
export const PICKING_ORDER_PROCESS_SYNC_BARCODE                         = '/picking/order-process/sync-barcode/:idUdm'                                       ;
export const PICKING_ORDER_PROCESS_FILE_ALL                             = '/picking/order-process/storage/all/:idOrderDetail'                                ;
export const PICKING_ORDER_PROCESS_FILE                                 = '/picking/order-process/storage/get/:idStorage'                                    ;
export const PICKING_ORDER_PROCESS_FILE_DELETE                          = '/picking/order-process/storage/delete/:idOrderDetail/:idStorage'                  ;
export const PICKING_ORDER_PROCESS_ASSOCIATE_UDM                        = '/picking/order-process/associateUdm/:idUdm/:idUdmHost'                            ;
export const PICKING_ORDER_PROCESS_ASSOCIATED_UDM                       = '/picking/order-process/associated-udm/:idOrderDetail'                             ;
export const PICKING_ORDER_PROCESS_IMPORT_BARCODES                      = '/picking/order-process/import-barcodes/:idOrder'                                  ;
export const PICKING_EXCEPTION_SOLVE                                    = '/picking/exception/solve'                                                         ;
export const PICKING_EXCEPTION_DELETE                                   = '/picking/exception/cancel'                                                        ;
export const PICKING_EXCEPTION_RETRY_PICKING                            = '/picking/exception/retry-picking'                                                 ;
export const PICKING_CHECK                                              = '/picking/check'                                                                   ;
export const PROCESSING_EXCEPTION_SOLVE                                 = '/processing/exception/solve'                                                      ;
export const PROCESSING_EXCEPTION_DELETE                                = '/processing/exception/cancel'                                                     ;
export const PROCESSING_EXCEPTION_RETRY_PICKING                         = '/processing/exception/retry-picking'                                              ;
export const PROCESSING_CHECK                                           = '/processing/check'                                                                ;
export const PROCESSING_UDM_ALL                                         = '/processing/udm/all'                                                              ;
export const PROCESSING_UDM_PICK                                        = '/processing/udm/pick'                                                             ;
export const PROCESSING_TYPE_FCC_ALL                                    = '/processing/type-fcc/all'                                                         ;
export const PROCESSING_TYPE_FCC_UPDATE_ORDER_PICKING                   = '/processing/type-fcc/update-order-picking'                                        ;
export const PROCESSING_TIME_ORDER                                      = '/processing/time/order/:idOrderDetail'                                            ;

// NAVGREEN IMPORT
export const CORE_NAVGREEN_IMPORT_ITEMS                                 = "/core/navgreen/import/items/"                                                     ;
export const CORE_NAVGREEN_IMPORT_ORDERS                                = "/core/navgreen/import/orders/:receiptDateTs"                                      ;
export const CORE_NAVGREEN_IMPORT_SINGLE_ITEM                           = "/core/navgreen/import/items/:idItem"                                              ;
export const CORE_NAVGREEN_IMPORT_VENDOR                                = "/core/navgreen/import/vendors"                                                    ;
export const CORE_NAVGREEN_IMPORT_CUSTOMER                              = "/core/navgreen/import/customers"                                                  ;
export const CORE_NAVGREEN_IMPORT_SALE_ORDERS                           = "/core/navgreen/import/sale-orders/:loadDate"                                      ;
export const CORE_NAVGREEN_IMPORT_ORDER_PROCESS                         = "/core/navgreen/import/order-process/:receiptDate"                                 ;
export const CORE_NAVGREEN_IMPORT_PROCESSING_TYPES                      = "/core/navgreen/import/processing-types"                                           ;
